* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  position: relative;
  overflow-x: hidden;
  height: 100%;
}
.LoadingScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}
.react-multi-carousel-list {
  padding: 1rem 0;
  background-color: #f3f7fc;
}
.error {
  color: #dc3545;
  font-size: clamp(12px, 2vw, 14px);
  display: block;
}
.ErrorText {
  color: #dc3545;
  font-size: clamp(12px, 2vw, 14px);
  display: block;
}

.errorDOB{
  color: #dc3545;
  font-size: clamp(12px, 2vw, 14px);
  display: block;
  margin-top: .5rem;

}
.sucess {
  color: green;
  font-size: clamp(12px, 2vw, 14px);
}

.ibeesIconContainer {
  position: relative;
}
.bessAnimation {
  width: 65px;
  position: fixed;
  top: 50%;
  right: 50%;
  z-index: 99;
}


/* Hoem Page Game and Puzzle */
.ImageText {
  font-family: "Nunito-Bold", Nunito;
  position: absolute;
  font-size: clamp(10rem, 1vw, 25rem);
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 100%;
  text-align: center;
  color: #fff;
}

/* ************** */
.unity {
  width: 60%;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
  width: 100%;
}
.react-datepicker__input-container input {
  padding: 0.5rem;
  font-size: clamp(10px, 2vw, 14px);
  font-weight: 400;
  line-height: 1.5;
  color: rgb(115, 115, 115);
  background-color: #fff;
  background-clip: padding-box;
  border: 1.5px solid rgb(106, 93, 141);
  outline: none;
  margin: 0.5rem 0rem;
  -webkit-text-stroke-width: 100%;
  width: 95%;
  border-radius: 10px;
}
.Dropdown-root {
  width: 95%;
  margin: 0.5rem 0;
}
.ReturnandRefundPolicy,
.ShippingandDeliveryPoliy {
  padding-top: 2rem;
  padding: 5%;
  background-color: "#f3f7fc";
}

.ShippingandDeliveryPoliy p {
  margin-bottom: 1rem;
  font-size: clamp(12px, 1vw, 2rem);
}
.ReturnandRefundPolicy li {
  padding: 0.25rem 0;
}
@media (max-width: 1390px) {
  .ImageText {
    font-size: clamp(9rem, 1vw, 25rem);
  }
}
@media (max-width: 1250px) {
  .ImageText {
    font-size: clamp(8rem, 1vw, 25rem);
  }
}

@media (max-width: 1000px) {
  .ReturnandRefundPolicy,
  .ShippingandDeliveryPoliy {
    padding: 2rem 2rem;
  }
}

/* DatePicker subscription page */

.DatePicker {
  width: 100%;
  margin-top: 0.5rem;
}
.DatePicker__input {
  width: 100%;
  text-align: left !important;
  line-height: 3.5;
  border-radius: 10px;
}
.DatePicker__input::placeholder {
  font-size: 1rem;
  color: red;
}

/* DropDwon */
.Dropdown-control {
  line-height: 2;
  border-radius: 10px !important;
}
.Dropdown-control .Dropdown-placeholder {
  color: #979797;
}
.Dropdown-arrow {
  top: 45% !important;
}


/* Privacy and policy */

.lst-kix_yki14kvk508x-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_ewee7io9ec40-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_ewee7io9ec40-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_ewee7io9ec40-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_ewee7io9ec40-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_ll1u2fms4ew3-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_ll1u2fms4ew3-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_ll1u2fms4ew3-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_ll1u2fms4ew3-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_wzk1stj1d0x-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_wzk1stj1d0x-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_wzk1stj1d0x-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_wzk1stj1d0x-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_ewee7io9ec40-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_wzk1stj1d0x-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_wzk1stj1d0x-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_ewee7io9ec40-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_ewee7io9ec40-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_wzk1stj1d0x-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_ewee7io9ec40-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_wzk1stj1d0x-4 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_ll1u2fms4ew3-1 {
  list-style-type: none;
}
ul.lst-kix_ll1u2fms4ew3-0 {
  list-style-type: none;
}
ul.lst-kix_ll1u2fms4ew3-3 {
  list-style-type: none;
}
ul.lst-kix_ll1u2fms4ew3-2 {
  list-style-type: none;
}
ul.lst-kix_ll1u2fms4ew3-5 {
  list-style-type: none;
}
ul.lst-kix_ll1u2fms4ew3-4 {
  list-style-type: none;
}
.lst-kix_ewee7io9ec40-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_wzk1stj1d0x-8 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_ll1u2fms4ew3-7 {
  list-style-type: none;
}
ul.lst-kix_ll1u2fms4ew3-6 {
  list-style-type: none;
}
ul.lst-kix_ll1u2fms4ew3-8 {
  list-style-type: none;
}
ul.lst-kix_nq7182uqjwls-7 {
  list-style-type: none;
}
ul.lst-kix_nq7182uqjwls-6 {
  list-style-type: none;
}
ul.lst-kix_nq7182uqjwls-8 {
  list-style-type: none;
}
ul.lst-kix_nq7182uqjwls-3 {
  list-style-type: none;
}
ul.lst-kix_nq7182uqjwls-2 {
  list-style-type: none;
}
ul.lst-kix_nq7182uqjwls-5 {
  list-style-type: none;
}
ul.lst-kix_nq7182uqjwls-4 {
  list-style-type: none;
}
.lst-kix_2wa12y2w4wyw-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_mp5b2yzfga1r-2 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_nq7182uqjwls-1 {
  list-style-type: none;
}
ul.lst-kix_nq7182uqjwls-0 {
  list-style-type: none;
}
.lst-kix_2wa12y2w4wyw-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_t3ipq480r3lb-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_mp5b2yzfga1r-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_2wa12y2w4wyw-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_t3ipq480r3lb-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_mp5b2yzfga1r-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_mp5b2yzfga1r-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_mp5b2yzfga1r-8 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_gv19t7sbeq2y-6 {
  list-style-type: none;
}
ul.lst-kix_gv19t7sbeq2y-5 {
  list-style-type: none;
}
ul.lst-kix_gv19t7sbeq2y-8 {
  list-style-type: none;
}
ul.lst-kix_gv19t7sbeq2y-7 {
  list-style-type: none;
}
ul.lst-kix_gv19t7sbeq2y-2 {
  list-style-type: none;
}
ul.lst-kix_gv19t7sbeq2y-1 {
  list-style-type: none;
}
ul.lst-kix_gv19t7sbeq2y-4 {
  list-style-type: none;
}
.lst-kix_ll1u2fms4ew3-4 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_gv19t7sbeq2y-3 {
  list-style-type: none;
}
ul.lst-kix_gv19t7sbeq2y-0 {
  list-style-type: none;
}
.lst-kix_ll1u2fms4ew3-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_ll1u2fms4ew3-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_j1nrgxr20bum-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_yki14kvk508x-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_j1nrgxr20bum-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_j1nrgxr20bum-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_p7djnsrudlxt-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_p7djnsrudlxt-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_yki14kvk508x-6 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_zhvc1w6zwwdh-3 {
  list-style-type: none;
}
ul.lst-kix_zhvc1w6zwwdh-2 {
  list-style-type: none;
}
.lst-kix_j1nrgxr20bum-2 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_zhvc1w6zwwdh-1 {
  list-style-type: none;
}
ul.lst-kix_zhvc1w6zwwdh-0 {
  list-style-type: none;
}
.lst-kix_p7djnsrudlxt-7 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_zhvc1w6zwwdh-7 {
  list-style-type: none;
}
ul.lst-kix_zhvc1w6zwwdh-6 {
  list-style-type: none;
}
ul.lst-kix_zhvc1w6zwwdh-5 {
  list-style-type: none;
}
ul.lst-kix_zhvc1w6zwwdh-4 {
  list-style-type: none;
}
ul.lst-kix_zhvc1w6zwwdh-8 {
  list-style-type: none;
}
.lst-kix_yki14kvk508x-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_yki14kvk508x-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_j1nrgxr20bum-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_p7djnsrudlxt-5 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_3nur5udrm0o3-7 {
  list-style-type: none;
}
ul.lst-kix_3nur5udrm0o3-6 {
  list-style-type: none;
}
ul.lst-kix_3nur5udrm0o3-5 {
  list-style-type: none;
}
ul.lst-kix_3nur5udrm0o3-4 {
  list-style-type: none;
}
ul.lst-kix_3nur5udrm0o3-8 {
  list-style-type: none;
}
.lst-kix_94sswbq13jv6-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_94sswbq13jv6-5 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_2oqas29gqv96-0 {
  list-style-type: none;
}
ul.lst-kix_3nur5udrm0o3-3 {
  list-style-type: none;
}
ul.lst-kix_2oqas29gqv96-1 {
  list-style-type: none;
}
ul.lst-kix_3nur5udrm0o3-2 {
  list-style-type: none;
}
ul.lst-kix_2oqas29gqv96-2 {
  list-style-type: none;
}
ul.lst-kix_3nur5udrm0o3-1 {
  list-style-type: none;
}
ul.lst-kix_2oqas29gqv96-3 {
  list-style-type: none;
}
.lst-kix_94sswbq13jv6-6 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_3nur5udrm0o3-0 {
  list-style-type: none;
}
ul.lst-kix_2oqas29gqv96-4 {
  list-style-type: none;
}
ul.lst-kix_2oqas29gqv96-5 {
  list-style-type: none;
}
ul.lst-kix_2oqas29gqv96-6 {
  list-style-type: none;
}
ul.lst-kix_2oqas29gqv96-7 {
  list-style-type: none;
}
.lst-kix_3nur5udrm0o3-7 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_2oqas29gqv96-8 {
  list-style-type: none;
}
.lst-kix_3nur5udrm0o3-6 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_crxash896rhg-8 {
  list-style-type: none;
}
ul.lst-kix_crxash896rhg-0 {
  list-style-type: none;
}
ul.lst-kix_crxash896rhg-1 {
  list-style-type: none;
}
ul.lst-kix_crxash896rhg-2 {
  list-style-type: none;
}
.lst-kix_94sswbq13jv6-1 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_crxash896rhg-3 {
  list-style-type: none;
}
ul.lst-kix_crxash896rhg-4 {
  list-style-type: none;
}
ul.lst-kix_crxash896rhg-5 {
  list-style-type: none;
}
ul.lst-kix_crxash896rhg-6 {
  list-style-type: none;
}
ul.lst-kix_crxash896rhg-7 {
  list-style-type: none;
}
ul.lst-kix_ewee7io9ec40-6 {
  list-style-type: none;
}
ul.lst-kix_ewee7io9ec40-5 {
  list-style-type: none;
}
ul.lst-kix_ewee7io9ec40-8 {
  list-style-type: none;
}
ul.lst-kix_ewee7io9ec40-7 {
  list-style-type: none;
}
ul.lst-kix_ewee7io9ec40-2 {
  list-style-type: none;
}
ul.lst-kix_ewee7io9ec40-1 {
  list-style-type: none;
}
ul.lst-kix_ewee7io9ec40-4 {
  list-style-type: none;
}
ul.lst-kix_ewee7io9ec40-3 {
  list-style-type: none;
}
ul.lst-kix_ewee7io9ec40-0 {
  list-style-type: none;
}
.lst-kix_2fbtuvtnjd20-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_3nur5udrm0o3-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_2fbtuvtnjd20-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_3nur5udrm0o3-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_t3ipq480r3lb-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_t3ipq480r3lb-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_2fbtuvtnjd20-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_2fbtuvtnjd20-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_t3ipq480r3lb-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_2hw9kz6u44iw-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_crxash896rhg-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_crxash896rhg-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_mp5b2yzfga1r-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_is5thuuhv809-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_z3k3uyrspei4-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_t3ipq480r3lb-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_2wa12y2w4wyw-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_2wa12y2w4wyw-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_lzusamb8z2s2-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_is5thuuhv809-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_z3k3uyrspei4-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_n4bidwhhmi1-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_n4bidwhhmi1-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_lzusamb8z2s2-4 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_lzusamb8z2s2-7 {
  list-style-type: none;
}
ul.lst-kix_2kv0uxxc96na-4 {
  list-style-type: none;
}
ul.lst-kix_lzusamb8z2s2-6 {
  list-style-type: none;
}
ul.lst-kix_2kv0uxxc96na-5 {
  list-style-type: none;
}
ul.lst-kix_lzusamb8z2s2-5 {
  list-style-type: none;
}
ul.lst-kix_2kv0uxxc96na-6 {
  list-style-type: none;
}
ul.lst-kix_lzusamb8z2s2-4 {
  list-style-type: none;
}
ul.lst-kix_2kv0uxxc96na-7 {
  list-style-type: none;
}
ul.lst-kix_2kv0uxxc96na-0 {
  list-style-type: none;
}
ul.lst-kix_2kv0uxxc96na-1 {
  list-style-type: none;
}
ul.lst-kix_2kv0uxxc96na-2 {
  list-style-type: none;
}
ul.lst-kix_lzusamb8z2s2-8 {
  list-style-type: none;
}
ul.lst-kix_2kv0uxxc96na-3 {
  list-style-type: none;
}
.lst-kix_2hw9kz6u44iw-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_lzusamb8z2s2-0 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_lzusamb8z2s2-3 {
  list-style-type: none;
}
ul.lst-kix_mp5b2yzfga1r-2 {
  list-style-type: none;
}
.lst-kix_mp5b2yzfga1r-7 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_2kv0uxxc96na-8 {
  list-style-type: none;
}
ul.lst-kix_lzusamb8z2s2-2 {
  list-style-type: none;
}
ul.lst-kix_mp5b2yzfga1r-1 {
  list-style-type: none;
}
ul.lst-kix_lzusamb8z2s2-1 {
  list-style-type: none;
}
ul.lst-kix_mp5b2yzfga1r-0 {
  list-style-type: none;
}
ul.lst-kix_lzusamb8z2s2-0 {
  list-style-type: none;
}
ul.lst-kix_9f3wljljc4cl-4 {
  list-style-type: none;
}
ul.lst-kix_mp5b2yzfga1r-6 {
  list-style-type: none;
}
ul.lst-kix_9f3wljljc4cl-5 {
  list-style-type: none;
}
ul.lst-kix_mp5b2yzfga1r-5 {
  list-style-type: none;
}
ul.lst-kix_9f3wljljc4cl-2 {
  list-style-type: none;
}
ul.lst-kix_mp5b2yzfga1r-4 {
  list-style-type: none;
}
ul.lst-kix_2gzpbpri73xf-8 {
  list-style-type: none;
}
ul.lst-kix_9f3wljljc4cl-3 {
  list-style-type: none;
}
ul.lst-kix_mp5b2yzfga1r-3 {
  list-style-type: none;
}
ul.lst-kix_2gzpbpri73xf-7 {
  list-style-type: none;
}
ul.lst-kix_9f3wljljc4cl-8 {
  list-style-type: none;
}
ul.lst-kix_9f3wljljc4cl-6 {
  list-style-type: none;
}
ul.lst-kix_mp5b2yzfga1r-8 {
  list-style-type: none;
}
ul.lst-kix_9f3wljljc4cl-7 {
  list-style-type: none;
}
ul.lst-kix_mp5b2yzfga1r-7 {
  list-style-type: none;
}
ul.lst-kix_94sswbq13jv6-4 {
  list-style-type: none;
}
.lst-kix_ll1u2fms4ew3-1 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_94sswbq13jv6-5 {
  list-style-type: none;
}
ul.lst-kix_94sswbq13jv6-6 {
  list-style-type: none;
}
ul.lst-kix_94sswbq13jv6-7 {
  list-style-type: none;
}
ul.lst-kix_9f3wljljc4cl-0 {
  list-style-type: none;
}
ul.lst-kix_94sswbq13jv6-8 {
  list-style-type: none;
}
ul.lst-kix_9f3wljljc4cl-1 {
  list-style-type: none;
}
.lst-kix_y7pwclul6sdi-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_y7pwclul6sdi-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_j1nrgxr20bum-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_96050txg8yyj-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_yki14kvk508x-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_z3k3uyrspei4-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_p7djnsrudlxt-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_p7djnsrudlxt-8 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_94sswbq13jv6-0 {
  list-style-type: none;
}
ul.lst-kix_94sswbq13jv6-1 {
  list-style-type: none;
}
ul.lst-kix_94sswbq13jv6-2 {
  list-style-type: none;
}
ul.lst-kix_94sswbq13jv6-3 {
  list-style-type: none;
}
.lst-kix_96050txg8yyj-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_j1nrgxr20bum-1 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_2gzpbpri73xf-2 {
  list-style-type: none;
}
ul.lst-kix_2gzpbpri73xf-1 {
  list-style-type: none;
}
ul.lst-kix_2gzpbpri73xf-0 {
  list-style-type: none;
}
ul.lst-kix_2gzpbpri73xf-6 {
  list-style-type: none;
}
ul.lst-kix_2gzpbpri73xf-5 {
  list-style-type: none;
}
.lst-kix_yki14kvk508x-3 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_2gzpbpri73xf-4 {
  list-style-type: none;
}
ul.lst-kix_2gzpbpri73xf-3 {
  list-style-type: none;
}
.lst-kix_p7djnsrudlxt-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_zhvc1w6zwwdh-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_zhvc1w6zwwdh-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_zhvc1w6zwwdh-1 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_by3n6wv39gy-7 {
  list-style-type: none;
}
ul.lst-kix_by3n6wv39gy-6 {
  list-style-type: none;
}
ul.lst-kix_by3n6wv39gy-8 {
  list-style-type: none;
}
ul.lst-kix_by3n6wv39gy-3 {
  list-style-type: none;
}
ul.lst-kix_by3n6wv39gy-2 {
  list-style-type: none;
}
ul.lst-kix_by3n6wv39gy-5 {
  list-style-type: none;
}
ul.lst-kix_by3n6wv39gy-4 {
  list-style-type: none;
}
ul.lst-kix_by3n6wv39gy-1 {
  list-style-type: none;
}
ul.lst-kix_by3n6wv39gy-0 {
  list-style-type: none;
}
.lst-kix_zhvc1w6zwwdh-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_1q7mvsqdu301-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_8pnh9c9qkxmk-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_1q7mvsqdu301-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_8pnh9c9qkxmk-4 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_qbm87cbd87sj-7 {
  list-style-type: none;
}
ul.lst-kix_qbm87cbd87sj-8 {
  list-style-type: none;
}
.lst-kix_iz1hib2s8nm0-1 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_qbm87cbd87sj-5 {
  list-style-type: none;
}
ul.lst-kix_qbm87cbd87sj-6 {
  list-style-type: none;
}
ul.lst-kix_qbm87cbd87sj-3 {
  list-style-type: none;
}
ul.lst-kix_qbm87cbd87sj-4 {
  list-style-type: none;
}
ul.lst-kix_qbm87cbd87sj-1 {
  list-style-type: none;
}
ul.lst-kix_qbm87cbd87sj-2 {
  list-style-type: none;
}
.lst-kix_iz1hib2s8nm0-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_8pnh9c9qkxmk-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_1q7mvsqdu301-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_8pnh9c9qkxmk-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_iz1hib2s8nm0-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_iz1hib2s8nm0-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_1q7mvsqdu301-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_1q7mvsqdu301-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_2hw9kz6u44iw-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_2enlnw7rbz8g-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_crxash896rhg-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_2enlnw7rbz8g-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_2enlnw7rbz8g-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_crxash896rhg-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_crxash896rhg-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_is5thuuhv809-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_is5thuuhv809-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_z3k3uyrspei4-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_z3k3uyrspei4-7 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_78dmlb89pbsw-1 {
  list-style-type: none;
}
ul.lst-kix_78dmlb89pbsw-0 {
  list-style-type: none;
}
ul.lst-kix_78dmlb89pbsw-3 {
  list-style-type: none;
}
ul.lst-kix_78dmlb89pbsw-2 {
  list-style-type: none;
}
ul.lst-kix_78dmlb89pbsw-5 {
  list-style-type: none;
}
ul.lst-kix_78dmlb89pbsw-4 {
  list-style-type: none;
}
.lst-kix_96050txg8yyj-8 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_78dmlb89pbsw-7 {
  list-style-type: none;
}
ul.lst-kix_78dmlb89pbsw-6 {
  list-style-type: none;
}
ul.lst-kix_78dmlb89pbsw-8 {
  list-style-type: none;
}
.lst-kix_n4bidwhhmi1-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_lzusamb8z2s2-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_2hw9kz6u44iw-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_lzusamb8z2s2-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_2hw9kz6u44iw-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_by3n6wv39gy-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_by3n6wv39gy-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_wnk0jj72rfzi-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_wnk0jj72rfzi-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_wnk0jj72rfzi-8 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_z3k3uyrspei4-1 {
  list-style-type: none;
}
.lst-kix_b9qr0kgzl99n-0 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_z3k3uyrspei4-2 {
  list-style-type: none;
}
ul.lst-kix_z3k3uyrspei4-0 {
  list-style-type: none;
}
.lst-kix_y7pwclul6sdi-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_b9qr0kgzl99n-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_y7pwclul6sdi-4 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_z3k3uyrspei4-7 {
  list-style-type: none;
}
ul.lst-kix_z3k3uyrspei4-8 {
  list-style-type: none;
}
ul.lst-kix_z3k3uyrspei4-5 {
  list-style-type: none;
}
.lst-kix_n4bidwhhmi1-2 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_z3k3uyrspei4-6 {
  list-style-type: none;
}
.lst-kix_2oqas29gqv96-7 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_z3k3uyrspei4-3 {
  list-style-type: none;
}
ul.lst-kix_z3k3uyrspei4-4 {
  list-style-type: none;
}
.lst-kix_96050txg8yyj-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_n4bidwhhmi1-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_vwhb8st0p6fw-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_2oqas29gqv96-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_a7ryqtr63qlv-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_96050txg8yyj-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_a7ryqtr63qlv-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_vwhb8st0p6fw-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_2gzpbpri73xf-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_94sswbq13jv6-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_94sswbq13jv6-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_nq7182uqjwls-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_3nur5udrm0o3-5 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_ivpeq0inwwcf-4 {
  list-style-type: none;
}
ul.lst-kix_ivpeq0inwwcf-5 {
  list-style-type: none;
}
.lst-kix_nq7182uqjwls-3 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_ivpeq0inwwcf-6 {
  list-style-type: none;
}
ul.lst-kix_ivpeq0inwwcf-7 {
  list-style-type: none;
}
ul.lst-kix_ivpeq0inwwcf-0 {
  list-style-type: none;
}
ul.lst-kix_ivpeq0inwwcf-1 {
  list-style-type: none;
}
.lst-kix_okq7q7h7egp5-7 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_ivpeq0inwwcf-2 {
  list-style-type: none;
}
ul.lst-kix_ivpeq0inwwcf-3 {
  list-style-type: none;
}
.lst-kix_3nur5udrm0o3-8 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_ivpeq0inwwcf-8 {
  list-style-type: none;
}
.lst-kix_2gzpbpri73xf-1 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_b9qr0kgzl99n-0 {
  list-style-type: none;
}
ul.lst-kix_b9qr0kgzl99n-1 {
  list-style-type: none;
}
ul.lst-kix_b9qr0kgzl99n-2 {
  list-style-type: none;
}
ul.lst-kix_b9qr0kgzl99n-3 {
  list-style-type: none;
}
.lst-kix_vxvllxsnnwu9-7 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_b9qr0kgzl99n-4 {
  list-style-type: none;
}
ul.lst-kix_b9qr0kgzl99n-5 {
  list-style-type: none;
}
.lst-kix_tuhhu3mhw8kp-6 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_b9qr0kgzl99n-6 {
  list-style-type: none;
}
.lst-kix_okq7q7h7egp5-4 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_b9qr0kgzl99n-7 {
  list-style-type: none;
}
ul.lst-kix_b9qr0kgzl99n-8 {
  list-style-type: none;
}
.lst-kix_78dmlb89pbsw-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_b9qr0kgzl99n-8 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_n4bidwhhmi1-3 {
  list-style-type: none;
}
ul.lst-kix_n4bidwhhmi1-2 {
  list-style-type: none;
}
ul.lst-kix_n4bidwhhmi1-1 {
  list-style-type: none;
}
ul.lst-kix_n4bidwhhmi1-0 {
  list-style-type: none;
}
.lst-kix_tuhhu3mhw8kp-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_78dmlb89pbsw-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_vxvllxsnnwu9-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_2fbtuvtnjd20-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_78dmlb89pbsw-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_2fbtuvtnjd20-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_gcpghmakc2df-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_t3ipq480r3lb-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_gcpghmakc2df-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_lj90cotw52yl-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_2wa12y2w4wyw-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_3nur5udrm0o3-0 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_n4bidwhhmi1-8 {
  list-style-type: none;
}
ul.lst-kix_n4bidwhhmi1-7 {
  list-style-type: none;
}
ul.lst-kix_n4bidwhhmi1-6 {
  list-style-type: none;
}
.lst-kix_lj90cotw52yl-5 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_n4bidwhhmi1-5 {
  list-style-type: none;
}
ul.lst-kix_n4bidwhhmi1-4 {
  list-style-type: none;
}
ul.lst-kix_2hw9kz6u44iw-7 {
  list-style-type: none;
}
ul.lst-kix_2hw9kz6u44iw-8 {
  list-style-type: none;
}
ul.lst-kix_2hw9kz6u44iw-5 {
  list-style-type: none;
}
.lst-kix_2enlnw7rbz8g-5 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_2hw9kz6u44iw-6 {
  list-style-type: none;
}
ul.lst-kix_2hw9kz6u44iw-3 {
  list-style-type: none;
}
.lst-kix_2wa12y2w4wyw-8 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_2hw9kz6u44iw-4 {
  list-style-type: none;
}
ul.lst-kix_2hw9kz6u44iw-1 {
  list-style-type: none;
}
ul.lst-kix_2hw9kz6u44iw-2 {
  list-style-type: none;
}
.lst-kix_avjyyfi6x85l-5 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_2hw9kz6u44iw-0 {
  list-style-type: none;
}
.lst-kix_2hw9kz6u44iw-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_a7ryqtr63qlv-2 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_yki14kvk508x-6 {
  list-style-type: none;
}
ul.lst-kix_yki14kvk508x-7 {
  list-style-type: none;
}
ul.lst-kix_yki14kvk508x-4 {
  list-style-type: none;
}
ul.lst-kix_yki14kvk508x-5 {
  list-style-type: none;
}
ul.lst-kix_yki14kvk508x-2 {
  list-style-type: none;
}
.lst-kix_rmnrj6rlqcr2-4 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_yki14kvk508x-3 {
  list-style-type: none;
}
ul.lst-kix_yki14kvk508x-0 {
  list-style-type: none;
}
ul.lst-kix_yki14kvk508x-1 {
  list-style-type: none;
}
.lst-kix_is5thuuhv809-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_c9m00xj39wdz-7 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_qbm87cbd87sj-0 {
  list-style-type: none;
}
ul.lst-kix_yki14kvk508x-8 {
  list-style-type: none;
}
.lst-kix_lzusamb8z2s2-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_y7pwclul6sdi-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_n4bidwhhmi1-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_gv19t7sbeq2y-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_iwyserb7qje9-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_mp5b2yzfga1r-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_crxash896rhg-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_wnk0jj72rfzi-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_ll1u2fms4ew3-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_b9qr0kgzl99n-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_qbm87cbd87sj-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_ivpeq0inwwcf-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_2oqas29gqv96-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_j1nrgxr20bum-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_z3k3uyrspei4-2 > li:before {
  content: "\0025a0  ";
}
li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.lst-kix_96050txg8yyj-3 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_y7pwclul6sdi-5 {
  list-style-type: none;
}
.lst-kix_yki14kvk508x-1 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_y7pwclul6sdi-4 {
  list-style-type: none;
}
ul.lst-kix_y7pwclul6sdi-3 {
  list-style-type: none;
}
ul.lst-kix_y7pwclul6sdi-2 {
  list-style-type: none;
}
.lst-kix_vwhb8st0p6fw-3 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_y7pwclul6sdi-8 {
  list-style-type: none;
}
ul.lst-kix_y7pwclul6sdi-7 {
  list-style-type: none;
}
ul.lst-kix_y7pwclul6sdi-6 {
  list-style-type: none;
}
.lst-kix_p7djnsrudlxt-6 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_y7pwclul6sdi-1 {
  list-style-type: none;
}
ul.lst-kix_y7pwclul6sdi-0 {
  list-style-type: none;
}
.lst-kix_by3n6wv39gy-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_9f3wljljc4cl-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_9f3wljljc4cl-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_9f3wljljc4cl-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_9cflybhedve-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_9f3wljljc4cl-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_9cflybhedve-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_9cflybhedve-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_9cflybhedve-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_9cflybhedve-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_9cflybhedve-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_9cflybhedve-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_9cflybhedve-4 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_p7djnsrudlxt-7 {
  list-style-type: none;
}
ul.lst-kix_p7djnsrudlxt-6 {
  list-style-type: none;
}
ul.lst-kix_p7djnsrudlxt-5 {
  list-style-type: none;
}
ul.lst-kix_p7djnsrudlxt-4 {
  list-style-type: none;
}
ul.lst-kix_p7djnsrudlxt-3 {
  list-style-type: none;
}
ul.lst-kix_p7djnsrudlxt-2 {
  list-style-type: none;
}
ul.lst-kix_p7djnsrudlxt-1 {
  list-style-type: none;
}
ul.lst-kix_p7djnsrudlxt-0 {
  list-style-type: none;
}
.lst-kix_wnyqqqsra14j-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_wnyqqqsra14j-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_wnyqqqsra14j-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_iwyserb7qje9-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_wnyqqqsra14j-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_wnyqqqsra14j-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_wnyqqqsra14j-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_wnyqqqsra14j-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_2kv0uxxc96na-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_2kv0uxxc96na-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_wnyqqqsra14j-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_2kv0uxxc96na-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_2kv0uxxc96na-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_2kv0uxxc96na-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_2kv0uxxc96na-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_2kv0uxxc96na-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_2kv0uxxc96na-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_wnyqqqsra14j-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_2kv0uxxc96na-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_9f3wljljc4cl-8 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_8pnh9c9qkxmk-1 {
  list-style-type: none;
}
ul.lst-kix_8pnh9c9qkxmk-0 {
  list-style-type: none;
}
.lst-kix_9f3wljljc4cl-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_9f3wljljc4cl-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_9f3wljljc4cl-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_9f3wljljc4cl-5 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_iz1hib2s8nm0-1 {
  list-style-type: none;
}
ul.lst-kix_iz1hib2s8nm0-0 {
  list-style-type: none;
}
.lst-kix_avjyyfi6x85l-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_avjyyfi6x85l-8 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_iz1hib2s8nm0-5 {
  list-style-type: none;
}
ul.lst-kix_iz1hib2s8nm0-4 {
  list-style-type: none;
}
ul.lst-kix_iz1hib2s8nm0-3 {
  list-style-type: none;
}
ul.lst-kix_iz1hib2s8nm0-2 {
  list-style-type: none;
}
.lst-kix_avjyyfi6x85l-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_c9m00xj39wdz-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_rmnrj6rlqcr2-3 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_lj90cotw52yl-5 {
  list-style-type: none;
}
ul.lst-kix_lj90cotw52yl-6 {
  list-style-type: none;
}
ul.lst-kix_lj90cotw52yl-7 {
  list-style-type: none;
}
ul.lst-kix_lj90cotw52yl-8 {
  list-style-type: none;
}
ul.lst-kix_lj90cotw52yl-1 {
  list-style-type: none;
}
ul.lst-kix_lj90cotw52yl-2 {
  list-style-type: none;
}
.lst-kix_c9m00xj39wdz-8 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_lj90cotw52yl-3 {
  list-style-type: none;
}
ul.lst-kix_lj90cotw52yl-4 {
  list-style-type: none;
}
.lst-kix_c9m00xj39wdz-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_rmnrj6rlqcr2-1 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_lj90cotw52yl-0 {
  list-style-type: none;
}
.lst-kix_gv19t7sbeq2y-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_gv19t7sbeq2y-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_gv19t7sbeq2y-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_iwyserb7qje9-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_gv19t7sbeq2y-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_rmnrj6rlqcr2-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_rmnrj6rlqcr2-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_iwyserb7qje9-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_iwyserb7qje9-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_iwyserb7qje9-3 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_iz1hib2s8nm0-8 {
  list-style-type: none;
}
ul.lst-kix_iz1hib2s8nm0-7 {
  list-style-type: none;
}
ul.lst-kix_iz1hib2s8nm0-6 {
  list-style-type: none;
}
.lst-kix_ivpeq0inwwcf-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_ivpeq0inwwcf-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_ivpeq0inwwcf-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_qbm87cbd87sj-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_ivpeq0inwwcf-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_qbm87cbd87sj-2 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_gcpghmakc2df-7 {
  list-style-type: none;
}
ul.lst-kix_gcpghmakc2df-6 {
  list-style-type: none;
}
.lst-kix_ivpeq0inwwcf-0 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_gcpghmakc2df-8 {
  list-style-type: none;
}
ul.lst-kix_gcpghmakc2df-1 {
  list-style-type: none;
}
ul.lst-kix_gcpghmakc2df-0 {
  list-style-type: none;
}
ul.lst-kix_gcpghmakc2df-3 {
  list-style-type: none;
}
ul.lst-kix_gcpghmakc2df-2 {
  list-style-type: none;
}
ul.lst-kix_gcpghmakc2df-5 {
  list-style-type: none;
}
ul.lst-kix_p7djnsrudlxt-8 {
  list-style-type: none;
}
ul.lst-kix_gcpghmakc2df-4 {
  list-style-type: none;
}
.lst-kix_qbm87cbd87sj-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_c9m00xj39wdz-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_9cflybhedve-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_qbm87cbd87sj-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_avjyyfi6x85l-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_c9m00xj39wdz-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_avjyyfi6x85l-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_qbm87cbd87sj-6 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_2wa12y2w4wyw-1 {
  list-style-type: none;
}
ul.lst-kix_is5thuuhv809-0 {
  list-style-type: none;
}
ul.lst-kix_2wa12y2w4wyw-2 {
  list-style-type: none;
}
.lst-kix_2gzpbpri73xf-7 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_wzk1stj1d0x-0 {
  list-style-type: none;
}
ul.lst-kix_2wa12y2w4wyw-0 {
  list-style-type: none;
}
ul.lst-kix_wzk1stj1d0x-1 {
  list-style-type: none;
}
ul.lst-kix_is5thuuhv809-3 {
  list-style-type: none;
}
ul.lst-kix_2wa12y2w4wyw-5 {
  list-style-type: none;
}
ul.lst-kix_wzk1stj1d0x-2 {
  list-style-type: none;
}
ul.lst-kix_is5thuuhv809-4 {
  list-style-type: none;
}
ul.lst-kix_2wa12y2w4wyw-6 {
  list-style-type: none;
}
ul.lst-kix_wzk1stj1d0x-3 {
  list-style-type: none;
}
ul.lst-kix_is5thuuhv809-1 {
  list-style-type: none;
}
ul.lst-kix_2wa12y2w4wyw-3 {
  list-style-type: none;
}
ul.lst-kix_wzk1stj1d0x-4 {
  list-style-type: none;
}
ul.lst-kix_is5thuuhv809-2 {
  list-style-type: none;
}
ul.lst-kix_2wa12y2w4wyw-4 {
  list-style-type: none;
}
ul.lst-kix_wzk1stj1d0x-5 {
  list-style-type: none;
}
ul.lst-kix_is5thuuhv809-7 {
  list-style-type: none;
}
ul.lst-kix_wzk1stj1d0x-6 {
  list-style-type: none;
}
ul.lst-kix_is5thuuhv809-8 {
  list-style-type: none;
}
.lst-kix_2gzpbpri73xf-3 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_wzk1stj1d0x-7 {
  list-style-type: none;
}
ul.lst-kix_is5thuuhv809-5 {
  list-style-type: none;
}
ul.lst-kix_2wa12y2w4wyw-7 {
  list-style-type: none;
}
ul.lst-kix_wzk1stj1d0x-8 {
  list-style-type: none;
}
ul.lst-kix_is5thuuhv809-6 {
  list-style-type: none;
}
ul.lst-kix_2wa12y2w4wyw-8 {
  list-style-type: none;
}
.lst-kix_2gzpbpri73xf-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_vwhb8st0p6fw-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_2gzpbpri73xf-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_nq7182uqjwls-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_okq7q7h7egp5-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_okq7q7h7egp5-6 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_2enlnw7rbz8g-7 {
  list-style-type: none;
}
ul.lst-kix_2enlnw7rbz8g-8 {
  list-style-type: none;
}
.lst-kix_nq7182uqjwls-5 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_2enlnw7rbz8g-5 {
  list-style-type: none;
}
ul.lst-kix_2enlnw7rbz8g-6 {
  list-style-type: none;
}
ul.lst-kix_c9m00xj39wdz-5 {
  list-style-type: none;
}
.lst-kix_tuhhu3mhw8kp-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_okq7q7h7egp5-1 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_c9m00xj39wdz-6 {
  list-style-type: none;
}
ul.lst-kix_c9m00xj39wdz-3 {
  list-style-type: none;
}
ul.lst-kix_c9m00xj39wdz-4 {
  list-style-type: none;
}
.lst-kix_nq7182uqjwls-4 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_c9m00xj39wdz-7 {
  list-style-type: none;
}
ul.lst-kix_c9m00xj39wdz-8 {
  list-style-type: none;
}
.lst-kix_tuhhu3mhw8kp-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_vxvllxsnnwu9-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_tuhhu3mhw8kp-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_vxvllxsnnwu9-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_nq7182uqjwls-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_vxvllxsnnwu9-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_tuhhu3mhw8kp-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_okq7q7h7egp5-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_nq7182uqjwls-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_78dmlb89pbsw-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_tuhhu3mhw8kp-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_lj90cotw52yl-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_78dmlb89pbsw-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_78dmlb89pbsw-6 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_c9m00xj39wdz-1 {
  list-style-type: none;
}
ul.lst-kix_c9m00xj39wdz-2 {
  list-style-type: none;
}
ul.lst-kix_c9m00xj39wdz-0 {
  list-style-type: none;
}
.lst-kix_vxvllxsnnwu9-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_78dmlb89pbsw-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_vxvllxsnnwu9-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_gcpghmakc2df-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_gcpghmakc2df-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_lj90cotw52yl-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_lj90cotw52yl-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_lj90cotw52yl-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_gcpghmakc2df-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_gcpghmakc2df-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_lj90cotw52yl-4 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_okq7q7h7egp5-6 {
  list-style-type: none;
}
.lst-kix_avjyyfi6x85l-7 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_okq7q7h7egp5-5 {
  list-style-type: none;
}
ul.lst-kix_okq7q7h7egp5-4 {
  list-style-type: none;
}
ul.lst-kix_okq7q7h7egp5-3 {
  list-style-type: none;
}
.lst-kix_a7ryqtr63qlv-8 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_okq7q7h7egp5-8 {
  list-style-type: none;
}
ul.lst-kix_okq7q7h7egp5-7 {
  list-style-type: none;
}
.lst-kix_avjyyfi6x85l-3 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_8pnh9c9qkxmk-7 {
  list-style-type: none;
}
ul.lst-kix_8pnh9c9qkxmk-6 {
  list-style-type: none;
}
ul.lst-kix_8pnh9c9qkxmk-8 {
  list-style-type: none;
}
ul.lst-kix_8pnh9c9qkxmk-3 {
  list-style-type: none;
}
ul.lst-kix_8pnh9c9qkxmk-2 {
  list-style-type: none;
}
ul.lst-kix_8pnh9c9qkxmk-5 {
  list-style-type: none;
}
.lst-kix_2enlnw7rbz8g-7 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_8pnh9c9qkxmk-4 {
  list-style-type: none;
}
.lst-kix_rmnrj6rlqcr2-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_c9m00xj39wdz-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_a7ryqtr63qlv-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_gcpghmakc2df-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_2enlnw7rbz8g-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_gv19t7sbeq2y-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_iwyserb7qje9-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_rmnrj6rlqcr2-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_gv19t7sbeq2y-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_gv19t7sbeq2y-8 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_iwyserb7qje9-2 {
  list-style-type: none;
}
ul.lst-kix_iwyserb7qje9-3 {
  list-style-type: none;
}
ul.lst-kix_iwyserb7qje9-4 {
  list-style-type: none;
}
ul.lst-kix_iwyserb7qje9-5 {
  list-style-type: none;
}
ul.lst-kix_iwyserb7qje9-6 {
  list-style-type: none;
}
ul.lst-kix_iwyserb7qje9-7 {
  list-style-type: none;
}
.lst-kix_iwyserb7qje9-6 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_iwyserb7qje9-8 {
  list-style-type: none;
}
ul.lst-kix_iwyserb7qje9-0 {
  list-style-type: none;
}
ul.lst-kix_iwyserb7qje9-1 {
  list-style-type: none;
}
.lst-kix_ivpeq0inwwcf-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_b9qr0kgzl99n-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_wnk0jj72rfzi-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_wnk0jj72rfzi-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_by3n6wv39gy-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_ivpeq0inwwcf-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_b9qr0kgzl99n-3 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_2enlnw7rbz8g-3 {
  list-style-type: none;
}
ul.lst-kix_2enlnw7rbz8g-4 {
  list-style-type: none;
}
.lst-kix_2oqas29gqv96-6 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_2enlnw7rbz8g-1 {
  list-style-type: none;
}
ul.lst-kix_2enlnw7rbz8g-2 {
  list-style-type: none;
}
ul.lst-kix_wnk0jj72rfzi-8 {
  list-style-type: none;
}
ul.lst-kix_2enlnw7rbz8g-0 {
  list-style-type: none;
}
ul.lst-kix_wnk0jj72rfzi-7 {
  list-style-type: none;
}
ul.lst-kix_wnk0jj72rfzi-4 {
  list-style-type: none;
}
ul.lst-kix_wnk0jj72rfzi-3 {
  list-style-type: none;
}
ul.lst-kix_wnk0jj72rfzi-6 {
  list-style-type: none;
}
ul.lst-kix_wnk0jj72rfzi-5 {
  list-style-type: none;
}
ul.lst-kix_wnk0jj72rfzi-0 {
  list-style-type: none;
}
ul.lst-kix_wnk0jj72rfzi-2 {
  list-style-type: none;
}
ul.lst-kix_wnk0jj72rfzi-1 {
  list-style-type: none;
}
.lst-kix_vwhb8st0p6fw-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_qbm87cbd87sj-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_2oqas29gqv96-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_c9m00xj39wdz-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_by3n6wv39gy-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_by3n6wv39gy-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_a7ryqtr63qlv-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_vwhb8st0p6fw-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_qbm87cbd87sj-7 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_okq7q7h7egp5-2 {
  list-style-type: none;
}
ul.lst-kix_okq7q7h7egp5-1 {
  list-style-type: none;
}
ul.lst-kix_okq7q7h7egp5-0 {
  list-style-type: none;
}
.lst-kix_zhvc1w6zwwdh-5 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_j1nrgxr20bum-4 {
  list-style-type: none;
}
ul.lst-kix_j1nrgxr20bum-3 {
  list-style-type: none;
}
ul.lst-kix_j1nrgxr20bum-6 {
  list-style-type: none;
}
ul.lst-kix_j1nrgxr20bum-5 {
  list-style-type: none;
}
ul.lst-kix_j1nrgxr20bum-8 {
  list-style-type: none;
}
ul.lst-kix_j1nrgxr20bum-7 {
  list-style-type: none;
}
.lst-kix_zhvc1w6zwwdh-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_zhvc1w6zwwdh-0 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_j1nrgxr20bum-0 {
  list-style-type: none;
}
.lst-kix_zhvc1w6zwwdh-2 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_j1nrgxr20bum-2 {
  list-style-type: none;
}
ul.lst-kix_j1nrgxr20bum-1 {
  list-style-type: none;
}
.lst-kix_zhvc1w6zwwdh-8 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_1q7mvsqdu301-8 {
  list-style-type: none;
}
.lst-kix_1q7mvsqdu301-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_1q7mvsqdu301-3 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_avjyyfi6x85l-0 {
  list-style-type: none;
}
.lst-kix_1q7mvsqdu301-4 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_1q7mvsqdu301-4 {
  list-style-type: none;
}
ul.lst-kix_avjyyfi6x85l-1 {
  list-style-type: none;
}
ul.lst-kix_1q7mvsqdu301-5 {
  list-style-type: none;
}
ul.lst-kix_avjyyfi6x85l-2 {
  list-style-type: none;
}
ul.lst-kix_1q7mvsqdu301-6 {
  list-style-type: none;
}
ul.lst-kix_avjyyfi6x85l-3 {
  list-style-type: none;
}
ul.lst-kix_1q7mvsqdu301-7 {
  list-style-type: none;
}
.lst-kix_8pnh9c9qkxmk-2 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_avjyyfi6x85l-4 {
  list-style-type: none;
}
ul.lst-kix_1q7mvsqdu301-0 {
  list-style-type: none;
}
.lst-kix_8pnh9c9qkxmk-3 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_avjyyfi6x85l-5 {
  list-style-type: none;
}
ul.lst-kix_1q7mvsqdu301-1 {
  list-style-type: none;
}
ul.lst-kix_avjyyfi6x85l-6 {
  list-style-type: none;
}
ul.lst-kix_1q7mvsqdu301-2 {
  list-style-type: none;
}
ul.lst-kix_avjyyfi6x85l-7 {
  list-style-type: none;
}
ul.lst-kix_1q7mvsqdu301-3 {
  list-style-type: none;
}
ul.lst-kix_avjyyfi6x85l-8 {
  list-style-type: none;
}
.lst-kix_iz1hib2s8nm0-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_iz1hib2s8nm0-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_8pnh9c9qkxmk-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_iz1hib2s8nm0-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_iz1hib2s8nm0-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_8pnh9c9qkxmk-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_1q7mvsqdu301-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_iz1hib2s8nm0-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_8pnh9c9qkxmk-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_2enlnw7rbz8g-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_crxash896rhg-4 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_2fbtuvtnjd20-4 {
  list-style-type: none;
}
ul.lst-kix_2fbtuvtnjd20-5 {
  list-style-type: none;
}
.lst-kix_is5thuuhv809-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_2hw9kz6u44iw-4 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_2fbtuvtnjd20-6 {
  list-style-type: none;
}
ul.lst-kix_2fbtuvtnjd20-7 {
  list-style-type: none;
}
.lst-kix_2enlnw7rbz8g-6 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_2fbtuvtnjd20-0 {
  list-style-type: none;
}
.lst-kix_a7ryqtr63qlv-3 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_2fbtuvtnjd20-1 {
  list-style-type: none;
}
.lst-kix_2hw9kz6u44iw-6 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_2fbtuvtnjd20-2 {
  list-style-type: none;
}
ul.lst-kix_2fbtuvtnjd20-3 {
  list-style-type: none;
}
.lst-kix_a7ryqtr63qlv-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_gcpghmakc2df-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_z3k3uyrspei4-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_is5thuuhv809-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_y7pwclul6sdi-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_n4bidwhhmi1-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_lzusamb8z2s2-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_lzusamb8z2s2-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_y7pwclul6sdi-2 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_a7ryqtr63qlv-7 {
  list-style-type: none;
}
ul.lst-kix_a7ryqtr63qlv-8 {
  list-style-type: none;
}
ul.lst-kix_a7ryqtr63qlv-5 {
  list-style-type: none;
}
ul.lst-kix_a7ryqtr63qlv-6 {
  list-style-type: none;
}
.lst-kix_n4bidwhhmi1-6 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_a7ryqtr63qlv-0 {
  list-style-type: none;
}
ul.lst-kix_a7ryqtr63qlv-3 {
  list-style-type: none;
}
ul.lst-kix_a7ryqtr63qlv-4 {
  list-style-type: none;
}
ul.lst-kix_a7ryqtr63qlv-1 {
  list-style-type: none;
}
ul.lst-kix_a7ryqtr63qlv-2 {
  list-style-type: none;
}
.lst-kix_crxash896rhg-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_wnk0jj72rfzi-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_wnk0jj72rfzi-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_b9qr0kgzl99n-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_b9qr0kgzl99n-4 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_vwhb8st0p6fw-4 {
  list-style-type: none;
}
ul.lst-kix_vwhb8st0p6fw-5 {
  list-style-type: none;
}
ul.lst-kix_vwhb8st0p6fw-6 {
  list-style-type: none;
}
ul.lst-kix_vwhb8st0p6fw-7 {
  list-style-type: none;
}
ul.lst-kix_vwhb8st0p6fw-0 {
  list-style-type: none;
}
.lst-kix_2oqas29gqv96-3 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_vwhb8st0p6fw-1 {
  list-style-type: none;
}
ul.lst-kix_vwhb8st0p6fw-2 {
  list-style-type: none;
}
.lst-kix_y7pwclul6sdi-8 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_vwhb8st0p6fw-3 {
  list-style-type: none;
}
ul.lst-kix_wnyqqqsra14j-8 {
  list-style-type: none;
}
ul.lst-kix_vwhb8st0p6fw-8 {
  list-style-type: none;
}
ul.lst-kix_wnyqqqsra14j-7 {
  list-style-type: none;
}
ul.lst-kix_wnyqqqsra14j-6 {
  list-style-type: none;
}
ul.lst-kix_wnyqqqsra14j-5 {
  list-style-type: none;
}
ul.lst-kix_wnyqqqsra14j-4 {
  list-style-type: none;
}
ul.lst-kix_wnyqqqsra14j-3 {
  list-style-type: none;
}
.lst-kix_2oqas29gqv96-5 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_wnyqqqsra14j-2 {
  list-style-type: none;
}
ul.lst-kix_wnyqqqsra14j-1 {
  list-style-type: none;
}
ul.lst-kix_wnyqqqsra14j-0 {
  list-style-type: none;
}
.lst-kix_96050txg8yyj-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_z3k3uyrspei4-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_96050txg8yyj-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_by3n6wv39gy-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_by3n6wv39gy-3 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_2fbtuvtnjd20-8 {
  list-style-type: none;
}
.lst-kix_vwhb8st0p6fw-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_vwhb8st0p6fw-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_2gzpbpri73xf-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_2gzpbpri73xf-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_94sswbq13jv6-8 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_tuhhu3mhw8kp-1 {
  list-style-type: none;
}
ul.lst-kix_tuhhu3mhw8kp-2 {
  list-style-type: none;
}
ul.lst-kix_tuhhu3mhw8kp-3 {
  list-style-type: none;
}
ul.lst-kix_tuhhu3mhw8kp-4 {
  list-style-type: none;
}
ul.lst-kix_vxvllxsnnwu9-0 {
  list-style-type: none;
}
ul.lst-kix_tuhhu3mhw8kp-5 {
  list-style-type: none;
}
ul.lst-kix_vxvllxsnnwu9-1 {
  list-style-type: none;
}
ul.lst-kix_tuhhu3mhw8kp-6 {
  list-style-type: none;
}
ul.lst-kix_vxvllxsnnwu9-2 {
  list-style-type: none;
}
ul.lst-kix_tuhhu3mhw8kp-7 {
  list-style-type: none;
}
ul.lst-kix_vxvllxsnnwu9-3 {
  list-style-type: none;
}
ul.lst-kix_tuhhu3mhw8kp-8 {
  list-style-type: none;
}
ul.lst-kix_vxvllxsnnwu9-4 {
  list-style-type: none;
}
ul.lst-kix_t3ipq480r3lb-6 {
  list-style-type: none;
}
ul.lst-kix_vxvllxsnnwu9-5 {
  list-style-type: none;
}
ul.lst-kix_t3ipq480r3lb-5 {
  list-style-type: none;
}
ul.lst-kix_vxvllxsnnwu9-6 {
  list-style-type: none;
}
ul.lst-kix_t3ipq480r3lb-8 {
  list-style-type: none;
}
ul.lst-kix_vxvllxsnnwu9-7 {
  list-style-type: none;
}
ul.lst-kix_t3ipq480r3lb-7 {
  list-style-type: none;
}
.lst-kix_94sswbq13jv6-3 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_vxvllxsnnwu9-8 {
  list-style-type: none;
}
ul.lst-kix_t3ipq480r3lb-2 {
  list-style-type: none;
}
.lst-kix_vwhb8st0p6fw-7 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_t3ipq480r3lb-1 {
  list-style-type: none;
}
ul.lst-kix_t3ipq480r3lb-4 {
  list-style-type: none;
}
ul.lst-kix_t3ipq480r3lb-3 {
  list-style-type: none;
}
ul.lst-kix_t3ipq480r3lb-0 {
  list-style-type: none;
}
.lst-kix_nq7182uqjwls-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_nq7182uqjwls-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_okq7q7h7egp5-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_okq7q7h7egp5-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_2gzpbpri73xf-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_tuhhu3mhw8kp-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_vxvllxsnnwu9-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_94sswbq13jv6-0 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_tuhhu3mhw8kp-0 {
  list-style-type: none;
}
.lst-kix_okq7q7h7egp5-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_78dmlb89pbsw-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_2fbtuvtnjd20-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_tuhhu3mhw8kp-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_vxvllxsnnwu9-3 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_96050txg8yyj-1 {
  list-style-type: none;
}
.lst-kix_2fbtuvtnjd20-5 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_96050txg8yyj-0 {
  list-style-type: none;
}
.lst-kix_78dmlb89pbsw-4 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_96050txg8yyj-7 {
  list-style-type: none;
}
ul.lst-kix_96050txg8yyj-6 {
  list-style-type: none;
}
ul.lst-kix_96050txg8yyj-8 {
  list-style-type: none;
}
ul.lst-kix_96050txg8yyj-3 {
  list-style-type: none;
}
ul.lst-kix_96050txg8yyj-2 {
  list-style-type: none;
}
ul.lst-kix_96050txg8yyj-5 {
  list-style-type: none;
}
.lst-kix_3nur5udrm0o3-4 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_96050txg8yyj-4 {
  list-style-type: none;
}
.lst-kix_gcpghmakc2df-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_t3ipq480r3lb-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_3nur5udrm0o3-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_2wa12y2w4wyw-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_lj90cotw52yl-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_lj90cotw52yl-6 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_9cflybhedve-5 {
  list-style-type: none;
}
ul.lst-kix_9cflybhedve-4 {
  list-style-type: none;
}
ul.lst-kix_9cflybhedve-7 {
  list-style-type: none;
}
ul.lst-kix_9cflybhedve-6 {
  list-style-type: none;
}
ul.lst-kix_9cflybhedve-8 {
  list-style-type: none;
}
.lst-kix_2fbtuvtnjd20-8 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_9cflybhedve-1 {
  list-style-type: none;
}
ul.lst-kix_9cflybhedve-0 {
  list-style-type: none;
}
ul.lst-kix_9cflybhedve-3 {
  list-style-type: none;
}
ul.lst-kix_9cflybhedve-2 {
  list-style-type: none;
}
.lst-kix_is5thuuhv809-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_2enlnw7rbz8g-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_crxash896rhg-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_is5thuuhv809-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_2wa12y2w4wyw-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_c9m00xj39wdz-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_t3ipq480r3lb-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_rmnrj6rlqcr2-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_z3k3uyrspei4-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_mp5b2yzfga1r-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_96050txg8yyj-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_gv19t7sbeq2y-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_rmnrj6rlqcr2-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_2hw9kz6u44iw-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_lzusamb8z2s2-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_iwyserb7qje9-4 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_rmnrj6rlqcr2-1 {
  list-style-type: none;
}
ul.lst-kix_rmnrj6rlqcr2-0 {
  list-style-type: none;
}
.lst-kix_ivpeq0inwwcf-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_2oqas29gqv96-8 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_rmnrj6rlqcr2-7 {
  list-style-type: none;
}
.lst-kix_wnk0jj72rfzi-1 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_rmnrj6rlqcr2-6 {
  list-style-type: none;
}
ul.lst-kix_rmnrj6rlqcr2-8 {
  list-style-type: none;
}
ul.lst-kix_rmnrj6rlqcr2-3 {
  list-style-type: none;
}
ul.lst-kix_rmnrj6rlqcr2-2 {
  list-style-type: none;
}
.lst-kix_by3n6wv39gy-6 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_rmnrj6rlqcr2-5 {
  list-style-type: none;
}
ul.lst-kix_rmnrj6rlqcr2-4 {
  list-style-type: none;
}
.lst-kix_b9qr0kgzl99n-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_y7pwclul6sdi-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_n4bidwhhmi1-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_p7djnsrudlxt-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_j1nrgxr20bum-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_2oqas29gqv96-0 > li:before {
  content: "\0025a0  ";
}
.lst-kix_yki14kvk508x-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_avjyyfi6x85l-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_qbm87cbd87sj-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_a7ryqtr63qlv-6 > li:before {
  content: "\0025cf  ";
}
.PrivacyAndPolicy ol {
  margin: 0;
  padding: 0;
}
.PrivacyAndPolicy table td,
.PrivacyAndPolicy table th {
  padding: 0;
}
.c15 {
  border-right-style: solid;
  padding: 5pt 5pt 5pt 5pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 142pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}
.c18 {
  border-right-style: solid;
  padding: 5pt 5pt 5pt 5pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: middle;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 67.8pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}
.c5 {
  border-right-style: solid;
  padding: 5pt 5pt 5pt 5pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 67.8pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}
.c10 {
  border-right-style: solid;
  padding: 5pt 5pt 5pt 5pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 211pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}
.c0 {
  margin-left: 36pt;
  padding-top: 12pt;
  padding-left: 0pt;
  padding-bottom: 12pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c33 {
  margin-left: 66pt;
  padding-top: 12pt;
  padding-left: 0pt;
  padding-bottom: 12pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c2 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Arial";
  font-style: normal;
}
.c1 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11.5pt;
  font-family: "Arial";
  font-style: normal;
}
.c27 {
  -webkit-text-decoration-skip: none;
  font-weight: 400;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-family: "Arial";
  font-style: normal;
}
.c19 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}
.c6 {
  color: #595959;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11.5pt;
  font-family: "Arial";
  font-style: normal;
}
.c9 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11.5pt;
  font-family: "Arial";
  font-style: normal;
}
.c3 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}
.c4 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c20 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c16 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c26 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c22 {
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-family: "Arial";
  font-style: italic;
}
.c47 {
  color: #6c7794;
  vertical-align: baseline;
  font-family: "Roboto";
  font-style: normal;
}
.c24 {
  text-decoration: none;
  vertical-align: baseline;
  font-family: "Arial";
  font-style: normal;
}
.c41 {
  border-spacing: 0;
  border-collapse: collapse;
width: 100%;}
.c29 {
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
  text-decoration-skip-ink: none;
}
.c32 {
  color: #000000;
  vertical-align: baseline;
  font-family: "Arial";
}
.c43 {
  background-color: #ffffff;
  padding: 72pt 72pt 72pt 72pt;
}
.c11 {
  font-size: 11.5pt;
  font-style: italic;
  font-weight: 700;
}
.c28 {
  color: #000000;
  font-weight: 700;
  font-size: 19.5pt;
}
.c7 {
  color: inherit;
  text-decoration: inherit;
}
.c8 {
  padding: 0;
  margin: 0;
}
.c36 {
  font-weight: 400;
  font-size: 11pt;
}
.c13 {
  color: #1155cc;
  font-size: 10.5pt;
}
.c14 {
  font-size: 11.5pt;
  font-weight: 700;
}
.c34 {
  font-weight: 400;
  font-size: 10.5pt;
}
.c12 {
  color: #595959;
}
.c46 {
  color: #000000;
}
.c31 {
  font-style: italic;
}
.c42 {
  height: 91pt;
}
.c21 {
  color: #1155cc;
}
.c39 {
  height: 75.2pt;
}
.c23 {
  height: 11pt;
}
.c17 {
  font-size: 11.5pt;
}
.c37 {
  color: #7f7f7f;
}
.c38 {
  text-decoration: none;
}
.c44 {
  background-color: #ffffff;
}
.c35 {
  margin-left: 15pt;
}
.c40 {
  font-weight: 400;
}
.c48 {
  font-size: 10.5pt;
}
.c45 {
  height: 24.2pt;
}
.c30 {
  height: 64pt;
}
.c25 {
  height: 91.8pt;
}
.title {
  padding-top: 0pt;
  color: #000000;
  font-size: 26pt;
  padding-bottom: 3pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.subtitle {
  padding-top: 0pt;
  color: #666666;
  font-size: 15pt;
  padding-bottom: 16pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PrivacyAndPolicy li {
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}
.PrivacyAndPolicy p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}
.PrivacyAndPolicy h1 {
  padding-top: 20pt;
  color: #000000;
  font-size: 20pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PrivacyAndPolicy h2 {
  padding-top: 18pt;
  color: #000000;
  font-size: 16pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PrivacyAndPolicy h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PrivacyAndPolicy h4 {
  padding-top: 14pt;
  color: #666666;
  font-size: 12pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PrivacyAndPolicy h5 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PrivacyAndPolicy h6 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}

@media(max-width:1000px){
  .errorDOB{
  margin-top: .1rem;
  }
}


