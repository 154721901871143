/* Styled by Shalu */
.logo_img {
  position: absolute;
  z-index: 99;
  top: 0;
  transform: translate(-50%, -30%);
  left: 50%;
}
.footer_maincontainer {
  /* padding-top: 15rem; */
  background-color: #f3f7fc;
  position: relative;
  z-index: 1;
}
.sub_container {
  position: relative;
  background-image: url("../../assets/Images/Footer/footer_bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.characters {
  /* background-color: red; */
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -60%);
  display: flex;
  z-index: 3;
  height: auto;
  z-index: -1;
}
.characters img {
  height: auto;
  max-width: 100%;
  object-fit: contain;
  /* transition: all 0.5s; */
  transition: all 1s;

  /* position: relative;
  z-index: 1; */
}
.characters img:hover {
  transform: translateY(-10px);
}
.footer_contents {
  /* background-color: red; */
  padding: 10rem 16rem 6rem 16rem;
}
.footer_contents ul {
  display: flex;
  list-style: none;
  justify-content: space-evenly;
}
.footer_contents li {
  font-family: "Nunito-Bold", "Nunito";
  font-weight: 700;
  font-size: 20px;
  color: #354f81;
}
.footer_images {
  display: flex;
  justify-content: space-evenly;
  margin: 15px;
}
.texts {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 2rem;
}
.footer_maincontainer a {
  text-decoration: none;
  position: relative;
  transition: 0.5s all;
}
.footer_maincontainer a:hover {
  transform: translateY(-3px);
}
.copyright {
  background-color: #481f9e;
  color: white;
  padding: 10px;
  text-align: center;
  /* position: absolute; */
  bottom: 0%;
  left: 0%;
  right: 0%;
  left: 0%;
}
.copyright p a {
  font-size: 15px;
  font-family: "Roboto-Regular", "Roboto";
  color: #fff;
}
.texts p {
  font-weight: 700;
  font-family: "Roboto-Bold", "Roboto";
  color: #481f9e;
  font-size: clamp(14px, 2vw, 16px);
}
.Owl {
  position: absolute;
  top: -15%;
}

.BannerMobi {
  display: none;
}
.socialMediaIcon {
  display: flex;
  gap: 1rem;
  align-items: center;
}

/* #path{
visibility: hidden;
} */

@media (max-width: 1500px) {
  .Owl {
    width: 55%;
    top: -8%;
  }
}
@media (max-width: 1000px) {
  .logo_img {
    width: 50%;
  }
  /* .sub_container {
    height: 86vh;
  } */
  .footer_contents ul {
    flex-direction: column;
    padding: 1rem;
    text-align: center;
  }
  .footer_contents li {
    margin: 20px;
    font-size: 19px;
  }
  .footer_images {
    flex-direction: column;
    margin: 1px 0px;
    align-items: center;
  }
  .footer_images img {
    width: 130px;
    margin: 10px;
  }
  .texts {
    /* flex-direction: column; */
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
  }
  .texts p {
    margin: 10px;
  }
  .footer_maincontainer {
    padding-top: 2rem;
  }
  .characters {
    transform: translate(0, -60%);
    left: 0;
    right: 0;
  }
  .characters img {
    width: 60px;
  }
  .footer_contents {
    padding: 3rem 1rem 2rem 1rem;
  }
}
