/*  created by daniya*/
.testimonialContainer {
  background-color: #f3f7fc;
  z-index: 100;
  overflow: hidden;
}
.bannerImage {
  position: relative;
}
.bannerImage img {
  width: 100%;
  background-color: #f3f7fc ;

}
.gridImageContainer {
  position: relative;
  background-color: #f3f7fc ;
  z-index: 1;
}
.Image1 {
  position: absolute;
  transform: translate(85%, 20%);
  z-index: 2;
  width: 70%;
}
.Image1 img {
  width: 80%;
}
.Image2 {
  position: absolute;
  top: 50%;
  transform: translate(-2%, 17%);
  z-index: 2;
}
.Image2 img {
  width: 70%;
}
.gridContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4rem;
  padding: 0rem 6rem 6rem 6rem;
  position: relative;
  z-index: 3;
  margin-top: -10px;
}
/* .gridImageDiv {
    position: relative;
    z-index: 5;
} */
.gridImageDiv1 {
  position: relative;
  z-index: 5;
  /* grid-column: 1/1;
  grid-row: 1; */
}
.gridImageDiv2 {
  position: relative;
  z-index: 5;
  /* grid-column: 1/2;
    grid-row: 1 / 2; */
}
.gridImageDiv3 {
  position: relative;
  z-index: 5;
  /* grid-column: 1/3;
    grid-row: 2 / 1; */
}
.gridImageDiv4 {
  position: relative;
  z-index: 5;
  /* grid-column: 2;
    grid-row: 2; */
}
.gridImageDiv5 {
  position: relative;
  z-index: 5;
  margin-top: 3rem;
  /* grid-column: 2;
    grid-row: 1/2; */
}
.gridImageDiv6 {
  position: relative;
  z-index: 5;
  margin-top: -3rem;
  /* grid-column: 2;
    grid-row: 2; */
}
.gridImageDiv7 {
  position: relative;
  z-index: 5;
  /* grid-column: 2;
    grid-row: 2; */
}
.gridImageDiv8 {
  position: relative;
  z-index: 5;
  margin-top: 3rem;
  /* grid-column: 2;
    grid-row: 2; */
}
.gridImageDiv9 {
  position: relative;
  z-index: 5;
  margin-top: -4rem;
  /* grid-column: 2;
    grid-row: 2; */
}
.gridItems {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
  padding: 3rem 2rem 2rem 2rem;
  background-color: #fff;
}
.gridItems h3 {
  font-size: clamp(15px, 5vw, 29px);
  font-family: Roboto-Medium, Roboto;
  font-weight: 500;
}
.headingImage {
  display: flex;
  justify-content: space-between;
}
.headingImage img {
  width: 100px;
  margin-top: -30px;
}
.headingImage p {
  font-size: clamp(12px, 5vw, 16px);
  font-family: Roboto-Regular, Roboto;
}
.imageDiv {
  /* width: 100px;
  height: 100px;
  background-color: rgb(56, 121, 121); */
  border-radius: 100%;
  position: absolute;
  z-index: 4;
  transform: translate(-25%, -50%);
  /* top: 0;
  left: 0; */
}
.imageDiv img {
  width: 100px;
}
@media (max-width: 1000px) {
  .gridContainer {
    grid-template-columns: repeat(1, 1fr);
    gap: 3rem;
    padding: 0px 20px 20px 20px;
  }
  .imageDiv img {
    width: 80px;
  }
  .gridItems {
    padding: 2rem;
  }
  .headingImage img {
    width: 80px;
    margin-top: -20px;
  }
  .gridImageDiv1,
  .gridImageDiv2,
  .gridImageDiv3,
  .gridImageDiv4,
  .gridImageDiv5,
  .gridImageDiv6,
  .gridImageDiv7,
  .gridImageDiv8,
  .gridImageDiv9 {
    margin: 0px;
  }
}
@media (max-width: 500px) {
  .headingImage img {
    width: 60px;
    margin-top: -20px;
  }
  .imageDiv img {
    width: 60px;
  }
}
