.navbar {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  background-color: #fff;
}

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  /* max-width: 1500px; */
  margin: 0 8%;
  /* background-color: red; */
}
.nav-logo img {
  width: 50px;
}

.main-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.nav-logo {
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  flex-grow: 1;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  align-items: center;
}

.nav-links {
  color: #354f81;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
  font-family: Nunito-Bold, Nunito;
  font-weight: 700;
}

.fa-code {
  margin-left: 1rem;
}

.nav-item {
  line-height: 40px;
  margin-right: 2rem;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: #9267d4;
}

.nav-item .active {
  color: #9267d4;
  border: 1px solid #9267d4;
}

.nav-icon {
  display: none;
}
.nav-itemTry {
  /* line-height: 40px; */
  margin-right: 2rem;
}

/* .tryTheApp {
  background-color: #428fff;
  color: #fff;
  border-radius: 50px;
} */
.tryTheAppButton {
  position: relative;
}
.tryTheAppButtonText {
  position: absolute;
}
.tryTheApp .nav-linksTry {
  position: relative;
  transition: 0.5s all;
}
.tryTheApp:hover {
  transform: translateY(-1px);
}
.tryTheAppSpan {
  position: absolute;
  color: #fff;

  /* background-color: #9267d4; */
  left: 0;
  right: 0;
  margin-top: 10px;
  /* top: 50%; */
}

.tryTheAppSpanFooter {
  position: absolute;
  color: #fff;
  /* background-color: #9267d4; */
  left: 30px;
  right: 0;
  top: 12px;
}
.Try_the_app_buttion,
.right_img,
.Leftimg,
.IbeesMobNav {
  display: none;
}
@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    border-top: 1px solid #fff;
    position: absolute;
    top: 70px;
    top: -1px;
    height: 100vh;
    right: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: rgb(62, 133, 238);
    right: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    height: 100vh;
  }
  .nav-item .active {
    /* border: 1px solid #9267d4; */
    border-radius: 50px;
    background-color: rgb(123, 176, 255);
    color: #fff;
    border: none;
  }
  .nav-links {
    padding: 10px;
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-60%, 40%);
    /* font-size: 1.8rem; */
    cursor: pointer;
    z-index: 99;
  }

  .nav-icon-Close {
    display: block;
    position: absolute;
    top: 10px;
    right: -30px;
    /* font-size: 1.8rem; */
    cursor: pointer;
    z-index: 99;
  }
  .navbar {
    height: 70px;
  }
  .nav-logo img {
    width: 45px;
  }
  .nav-item {
    margin: 1rem 5rem;
    color: #fff;
    width: 50%;
  }
  .nav-itemTry {
    margin: 2rem;
  }
  .nav-links {
    color: #ffff;
  }
  .Leftimg {
    display: block;
    position: absolute;
    z-index: 999;
    left: 0;
    bottom: 0;
  }
  .right_img {
    display: block;
    position: absolute;
    z-index: 999;
    right: 0;
    bottom: 5%;
  }
  .IbeesMobNav {
    display: block;
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
  }

  .tryTheApp {
    display: none;
  }
  .Try_the_app_buttion {
    display: block;
    position: absolute;
    bottom: 18%;
    left: 50%;
    transform: translateX(-50%);
  }
  /* .tryTheApp_bee {
    width: 30px;
    right: 10px;
    position: absolute;
  } */

  .tryTheAppSpanFooter {
    left: 5px;
  }
}
