:root {
  --heading: clamp(2rem, 5vw, 5rem);
  --para: clamp(0.8rem, 2vw, 1.4rem);
}

/* Banner */
.bannerContainer {
  display: grid;
  grid-template-columns: 40% 60%;
  min-height: calc(100vh - 80px);
  align-items: center;
  gap: 1rem;
  position: relative;
}
.leftContainer {
  color: #7468dc;
  letter-spacing: 2px;
  justify-self: flex-end;
}
/* .icons_shape {
  position: relative;
} */
.blueShape {
  position: absolute;
  left: -80px;
  top: -100px;
}
.triggerfunlearning img {
  width: 35px;
}

.goodandFun_right {
  position: relative;
  margin-top: 2rem;
}
.goodandFun_right h1 {
  font-size: clamp(4rem, 2vw, 7rem);
  font-family: "Nunito", sans-serif;
  font-weight: 700;
}
.goodandFun_right h2 {
  font-size: clamp(3rem, 1.5vw, 5rem);
  font-family: "Nunito", sans-serif;
  font-weight: 700;
}
.goodandFun_right .bullets ul li {
  font-size: clamp(0.5rem, 1.3vw, 3rem);
  text-align: left;
  font-family: Nunito-Regular, Nunito;
  font-weight: 400;
  color: #fff;
}

.bendiving {
  position: absolute;
  left: 0;
}
.leftContainer .bannerText div:first-child {
  display: flex;
  align-items: center;
}
.bannerText {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 120px;
  margin-top: -150px;
}
.bannerText img {
  max-width: 100%;
  height: auto;
  /* padding: 0 10%; */
}
.funlearning_container {
  position: relative;
}
.funlearning_container img {
  max-width: 100%;
  height: auto;
}
.funlearning_container .mainImg {
  margin-top: 2rem;
}
.leftContainer h3 {
  font-size: clamp(1.2rem, 2vw, 4rem);
  font-weight: 500;
  font-family: "Nunito-Regular", Nunito;
}
.leftContainer h1 {
  font-size: clamp(4rem, 5vw, 6rem);
  font-weight: 700;
  font-family: "Nunito-Bold", Nunito;
}
/* rightContainer */

.rightContainer_sub {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  min-height: calc(100vh - 80px);
}
/* .rightContainer {
  max-width: 100%;
  min-height: calc(100vh - 80px);
} */
/* Banner Images */
.owlbody {
  position: absolute;
  bottom: 100px;
  right: -70px;
  z-index: 1;
  width: 140px;
  transition: 0.8s;
}
.owlbody:hover {
  right: -30px;
}
.owlhand {
  position: absolute;
  bottom: 140px;
  right: -40px;
  z-index: 3;
  width: 65px;
  transition: 0.8s;
}
.owlbody:hover + .owlhand {
  right: 0px;
}

.lolabody {
  position: absolute;
  bottom: 100px;
  right: -20px;
  z-index: 1;
  width: 140px;
  transition: 0.8s;
  pointer-events: auto;
  cursor: pointer;
}
/* .lolabody:hover {
  right: 10px;
} */
.lolahand {
  position: absolute;
  bottom: 165px;
  left: -40px;
  z-index: 4;
  width: 70px;
  transition: 0.8s;
}

.benbody {
  position: absolute;
  bottom: 100px;
  right: -20px;
  z-index: 1;
  width: 150px;
  transition: 0.8s;
}
/* .benbody:hover {
  right: 10px;
} */
.benhand {
  position: absolute;
  bottom: 185px;
  left: -46px;
  z-index: 4;
  width: 80px;
  transition: 0.8s;
}

.tomhand {
  position: absolute;
  bottom: 180px;
  left: -40px;
  z-index: 4;
  width: 80px;
  transition: 0.8s;
}

.elibody {
  position: absolute;
  bottom: 100px;
  right: -25px;
  z-index: 1;
  width: 185px;
  transition: 0.8s;
}
/* .elibody:hover {
  right: 10px;
} */
.elihand {
  position: absolute;
  bottom: 195px;
  left: -40px;
  z-index: 4;
  width: 75px;
  transition: 0.8s;
}

.rightContainer .whiteOuter {
  position: relative;
}

.rightContainer .white {
  /* background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 31% 0% 0% 32% / 60% 10% 10% 60%;

  margin-right: -60px; */
  background-image: url("../../assets/Images/Banner/1.svg");
  padding: 110px;
  position: relative;
  z-index: 2;
  pointer-events: none;
}

.rightContainer .green {
  /* background-color: #79cb51;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 31% 0% 0% 32% / 60% 10% 10% 60%;

  margin-right: -60px; */
  background-image: url("../../assets/Images/Banner/2.svg");
  margin-left: -50px;
  position: relative;
  z-index: 2;
  transition: 0.8s;
}

.rightContainer .blue {
  /* background-color: #428fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 31% 0% 0% 32% / 60% 10% 10% 60%;

  margin-right: -60px; */
  background-image: url("../../assets/Images/Banner/3.svg");
  margin-left: -50px;
  position: relative;
  z-index: 2;
}

.rightContainer .yellow {
  background-image: url("../../assets/Images/Banner/4.svg");
  margin-left: -50px;
  position: relative;
  z-index: 2;
}

.rightContainer .violet {
  /* background-color: #7569de;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 31% 0% 0% 32% / 60% 10% 10% 60%; */
  background-image: url("../../assets/Images/Banner/5.svg");
  margin-left: -50px;
  position: relative;
  z-index: 2;
}

/* home_second_container */
.homeBgClr {
  background-color: #f3f7fc;
  position: relative;
  z-index: 2;
}
.home_second_container {
  background: rgb(243, 247, 252);
  background: linear-gradient(
    0deg,
    rgba(243, 247, 252, 1) 0%,
    rgba(243, 247, 252, 1) 53%,
    rgba(209, 229, 255, 1) 100%
  );
  border-radius: 49% 49% 0% 0% / 5% 5% 18% 19%;
  margin-top: -40px;
  position: relative;
  z-index: 9;
  text-align: center;
}
.home_second_sub_container {
  padding: 10rem 0;
  /* width: 30%; */
  margin: auto;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  justify-items: center;
  position: relative;
  /* background-color: red; */
}

.image_one {
  left: -50px;
  top: 45%;
  position: absolute;
}
.image_four {
  right: 0px;
  top: -2rem;
  position: absolute;
}
.image_two {
  left: 0%;
  top: -1.5rem;
  position: absolute;
}
.image_three {
  left: 0;
  bottom: -50px;
  position: absolute;
}
.image_five {
  right: -10%;
  bottom: -2rem;
  position: absolute;
}
.image_six {
  right: -50px;
  top: 30%;
  position: absolute;
}
.image_seven {
  right: 10%;
  bottom: -30%;
  position: absolute;
}

.firsth1 {
  font-size: clamp(1.8rem, 7vw, 8rem);
  color: rgb(116, 104, 220);
  font-family: Nunito-Bold, Nunito;
  font-weight: 700;
}

.firsth2 {
  font-size: clamp(1.5rem, 4vw, 6rem);
  color: rgb(116, 104, 220);
  font-family: "Nunito-Regular", Nunito;
  font-weight: 400;
}

.CenterImage {
  position: relative;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /* border: 1px solid #fff; */
  background-color: #f3f7fc;
  display: flex;
  justify-content: center;
}
.CenterImage img {
  max-width: 100%;
}
.CenterImage .bullets {
  font-size: clamp(5rem, 1vw, 10rem);
  margin-top: 25%;
  position: absolute;
  text-align: left;
  font-family: Nunito-Regular, Nunito;
  font-weight: 400;
  color: #57758c;
}
.CenterImage .bullets ul {
  font-size: clamp(5rem, 1vw, 10rem);
  text-align: left;
  font-family: Nunito-Regular, Nunito;
  font-weight: 400;
  color: #57758c;
}

.image_let {
  position: absolute;
  width: 50%;
  left: -40%;
  /* transform: rotate(-10deg); */
  bottom: 15%;
  opacity: 0;
}

.image_right {
  position: absolute;
  width: 50%;
  right: -40%;
  bottom: 6%;
  opacity: 1;
}
.Rightflight {
  position: absolute;
  top: -5%;
  right: 0;
}
.Leftflight {
  position: absolute;
  top: 5%;
  right: -10%;
}
.pyramid {
  /* width: 50%; */
  right: -40%;
  bottom: 10%;
  position: absolute;
}
.puzzle_img {
  left: -40%;
  top: 30%;
  position: absolute;
}

.bullets ul {
  list-style: none;
}

.bullets ul li::before {
  content: "\2022";
  color: rgb(67, 174, 222);
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
/* Thrid */

.stories_rhymes_container {
  padding: 0 10%;
  position: relative;
}
.stories_rhymes_container img {
  max-width: 100%;
  height: auto;
}

.stories_rhymes_subcontainer {
  position: relative;
  /* background-color: red; */
}

.stories_rhymes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  align-items: center;
}
.rhymes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  align-items: center;
  position: absolute;
  top: 0;
}

.left_container h1 {
  font-size: var(--heading);
  color: #79cb51;
}
.left_container p {
  color: #57758c;
  font-size: var(--para);
  line-height: 2;
}
.left_container_Rhymes h1 {
  font-size: var(--heading);
  color: #ffca2a;
}
.left_container_Rhymes p {
  color: #57758c;
  font-size: var(--para);
  line-height: 2;
}

/* Improve Child's Brain Growth */

.mainContainer {
  position: relative;
  overflow-x: hidden;
}
.bgImage {
  background-image: url("../../assets/Images/BrainGrowth/BrainGrowthbluebg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.BrainGrowth {
  display: grid;
  grid-template-columns: 35% 65%;
  margin: 0 10% 7%;
  background-color: #fff5df;
  padding: 1% 5rem;
  align-items: center;
  /* gap: 5rem; */
  border-radius: 25px;
  position: relative;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.BrainGrowth .bullets ul li {
  font-size: clamp(0.5rem, 1.3vw, 3rem);
  text-align: left;
  font-family: Nunito-Regular, Nunito;
  font-weight: 400;
  color: #57758c;
}

.BrainGrowth h1 {
  color: #9267d4;
  font-size: clamp(3rem, 3.3vw, 7rem);
  font-family: "Nunito", sans-serif;
}
.BrainGrowth p {
  color: #2c3547;
  font-size: clamp(1.5rem, 1vw, 2rem);
  font-family: "Nunito", sans-serif;
}
.BrainGrowth .left {
  /* position: absolute;
  top: 50%; */
  transform: translateX(25%);
}
.BrainGrowth .left img {
  max-width: 100%;
  height: auto;
}

.goodandFun {
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 0% 13%;
  /* align-items: center; */
  color: #fff;
}
.goodandFunHead {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  line-height: 1.2;
}
.goodandFunHead h1 {
  font-size: 5vw;
}
.goodandFunHead h2 {
  font-size: 6.1vw;
}
.goodandFunHead h3 {
  font-size: 4.1vw;
}
.goodandFunHead img {
  max-width: 100%;
  height: auto;
}
.goodandFun p {
  font-family: "Nunito-Regular", Nunito;
  font-size: clamp(1rem, 1.3vw, 2rem);
}
.purple {
  position: absolute;
  left: 0;
  transform: translateX(-70%);
  top: 30%;
}

.greenright {
  position: absolute;
  right: 0;
  transform: translateX(70%);
  top: 40%;
}
.tomastronaut {
  position: absolute;
  right: 0;
  top: 40%;
}

/* Parental Access */

.parentalAccess {
  display: grid;
  grid-template-columns: 60% 40%;
  margin: 0 10% 4%;
  background-color: #fff5df;
  /* padding: 5% 4rem 5% 0; */
  padding-left: 5%;
  align-items: center;
  border-radius: 25px;
  position: relative;
  box-shadow: rgb(0 0 0 / 10%) 0px 10px 15px -3px,
    rgb(0 0 0 / 5%) 0px 4px 6px -2px;
}
.parentalAccess .bullets ul li {
  font-size: clamp(0.5rem, 1.3vw, 3rem);
  text-align: left;
  font-family: Nunito-Regular, Nunito;
  font-weight: 400;
  color: #57758c;
}

.parentalAccess h1 {
  color: #9267d4;
  font-size: clamp(4rem, 2vw, 7rem);
  font-family: "Nunito", sans-serif;
}
.parentalAccess p {
  color: #2c3547;
  font-size: clamp(1.5rem, 1vw, 2rem);
  font-family: "Nunito", sans-serif;
}
.parentalAccess .right {
  margin-left: -150px;
}
/* .parentalAccess .left {
  transform: translateX(-25%);
} */
.parentalAccess .left img {
  width: 100%;
  height: auto;
}

/* carousel */
.Carousel {
  margin: 0rem 0px 0% 10%;
}
.carousel_button_group {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  gap: 2rem;
  /* padding-bottom: 6rem; */
  /* background-color: red; */
}
.Carousel h1 {
  color: #9267d4;
  font-size: clamp(2rem, 6vw, 4rem);
  width: 30%;
}

.carosuelBtn {
  height: 50px;
  width: 50px;
  padding: 10px;
  background-color: #fff;
  border-radius: 50%;
  border: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.testimonial {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #fff;
  border-radius: 20px;
  padding: 2rem 2rem 2rem 1rem;
  margin-right: 2rem;
}

.testimonial h2 {
  color: #a3b5c4;
  font-family: "Nunito-Bold", Nunito;
  font-weight: 700;
}
.testimonial p {
  color: #a3b5c4;
  font-family: "Nunito-Bold", Nunito;
  font-weight: 500;
}
.testimonial_paraSub {
  display: flex;
  justify-content: space-between;
}

.BannerMobi,
.CenterImageMobile {
  display: none;
}
@media (max-width: 1500px) {
  .rightContainer .white {
    padding: 90px;
  }
  .CenterImage .bullets {
    margin-top: 30%;
  }
  .BrainGrowth {
    grid-template-columns: 45% 55%;
  }
}

@media (max-width: 1000px) {
  .BannerMobi {
    display: block;
  }
  .CenterImageMobile {
    display: block;
  }
  .BannerMobi img {
    margin-top: 20px;
  }
  .rightContainer_sub {
    display: none;
  }
  .leftContainer {
    margin-top: -10%;
  }
  .characters img {
    max-width: 100%;
    height: auto;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  .triggerfunlearning {
    display: none;
  }
  .CenterImage {
    display: none;
  }
  .BrainGrowth,
  .parentalAccess,
  .goodandFun,
  .bannerContainer {
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: flex-start;
    gap: 0;
  }
  .BrainGrowth .left,
  .bannerContainer .rightContainer {
    order: -1;
  }
  .blueShape {
    z-index: -1;
    left: -25px;
    top: -25px;
  }
  .bannerText {
    margin: 0;
  }
  .funlearning_container {
    display: flex;
    justify-content: center;
  }
  .funlearning_container img {
    max-width: 80%;
  }

  /* preScholl at home */
  .CenterImageMobile {
    display: flex;
    justify-content: center;
  }
  .CenterImageMobile img {
    display: flex;
    margin: auto;
  }
  /* .CenterImageMobile .bullets {
    text-align: left;
    font-family: Nunito-Regular, Nunito;
    font-weight: 400;
    color: #57758c;
  }
  .CenterImageMobile .bullets ul {
    text-align: left;

  } */
  .CenterImageMobile .bullets {
    margin: 0 50px;
  }
  .CenterImageMobile .bullets h1 {
    color: rgb(103, 47, 217);
    font-family: Nunito-Bold, Nunito;
    font-weight: 700;
    text-align: center;
    margin-bottom: 1rem;
    font-size: 8vw;
  }

  .CenterImageMobile .bullets ul li {
    font-size: 4vw;
    color: #57758c;
    font-family: Nunito-Regular, Nunito;
    font-weight: 400;
  }

  .firsth1 {
    font-size: clamp(2rem, 16vw, 9rem);
  }
  .firsth2 {
    font-size: clamp(2rem, 10vw, 7rem);
  }
  .home_second_sub_container {
    padding: 2rem 0;
  }
  .BrainGrowth {
    padding: 2rem;
    margin: 20px;
  }
  .BrainGrowth h1 {
    font-size: 8vw;
    text-align: center;
  }
  .BrainGrowth .left {
    transform: translateX(0);
  }
  .BrainGrowth .bullets ul li {
    font-size: 4vw;
  }
  .tomastronaut {
    display: none;
  }
  /* .goodandFun */
  .goodandFun {
    margin: 20px;
    justify-items: center;
  }
  .goodandFun_right h1 {
    font-size: 15vw;
    text-align: center;
  }
  .goodandFun_right h2 {
    font-size: 8vw;
    text-align: center;
  }
  .goodandFun_right {
    margin-top: 0;
  }
  .goodandFun_right .bullets ul li {
    font-size: 4vw;
  }

  /* parentalAccess */
  .parentalAccess {
    margin: 20px;
    padding: 2rem;
  }
  .parentalAccess h1 {
    font-size: 8vw;
  }
  .parentalAccess .right {
    margin-left: 0;
  }

  .parentalAccess .bullets ul li {
    font-size: 4vw;
  }
  /* Carousel */
  .Carousel {
    margin: 0px;
    /* display: none; */
    width: 100%;
  }
  .CarouselHeading {
    display: flex;
    justify-content: center;
  }

  .Carousel h1 {
    width: 60%;
    text-align: center;
  }
  .greenright {
    display: none;
  }
}
